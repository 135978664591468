import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaUsers } from "react-icons/fa6";
import { PiUserSwitchFill } from "react-icons/pi";
import { MdAttachMoney } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { Data } from "../../Common/Data";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../Actions/adminActions";

const Dashboard = () => {
const dispatch =  useDispatch()
  const {dashboardData} = useSelector((state) => state.admin);
  useEffect(() => {
    //console.log("user",dashboardData);
    dispatch(fetchDashboardData());
  }, []);

  return (
    <Container>
      <section>
        <Row md="12">
          <Col md="4">
            <div className="dashboardAdminCard">
              <div>
                <h4>Total Users</h4>
                <p>{dashboardData?.totalUsers}</p>
              </div>
              <div>
                <i>
                  <FaUsers />
                </i>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h4>Active</h4>
                <p>{dashboardData?.activeUsers}</p>
              </div>
              <div>
                <i>
                  <PiUserSwitchFill />
                </i>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h4>Total Income</h4>
                <p>{dashboardData?.totalIncome}</p>
              </div>
              <div>
                <i>
                  <MdAttachMoney />
                </i>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h4>Total Investment</h4>
                <p>{dashboardData?.totalInvestment}</p>
              </div>
              <div>
                <i>
                  <MdAttachMoney />
                </i>
              </div>
            </div>
          </Col>
        </Row>

        <Row md="12">
          <Col md="4">
            <div className="dashboardWithdrawCard">
              <i>
                <BiMoneyWithdraw />
              </i>
              <div>
                <h3>Withdrawl</h3>
              </div>
              <div className="partition"></div>
              <div>
                <h4>Approval</h4>
              </div>{" "}
              <p>{dashboardData?.approvedWithdrawals}</p>
            </div>
          </Col>
          <Col md="4">
            <div className="dashboardWithdrawCard">
              {" "}
              <i>
                <BiMoneyWithdraw />
              </i>
              <div>
                <h3>Withdrawl</h3>
              </div>
              <div className="partition"></div>
              <div>
                <h4>Pending</h4>
              </div>{" "}
              <p>{dashboardData?.pendingWithdrawals}</p>
            </div>
          </Col>
          <Col md="4">
            <div className="dashboardWithdrawCard">
              {" "}
              <i>
                <BiMoneyWithdraw />
              </i>
              <div>
                <h3>Withdrawl</h3>
              </div>
              <div className="partition"></div>
              <div>
                <h4>Cancelled</h4>
              </div>
              <p>{dashboardData?.rejectedWithdrawals}</p>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <Row md="12">
          <Col md="4">
            <div className="dashboardInvestment">
              <h3>Investment</h3>

              <div className="dashboardInvestmentContent">
                <h4>Total </h4>
                <p>{dashboardData?.totalIncome}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Today</h4>
                <p>{"0"}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Yesterday</h4>
                <p>{dashboardData?.todayIncome}</p>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="dashboardInvestment">
              <h3>Withdrawl</h3>
              <div className="dashboardInvestmentContent">
                <h4>Active</h4>

                <p>{"0"}</p>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="dashboardInvestment">
              <h3>Wallet</h3>
              <div className="dashboardInvestmentContent">
                <h4>Main Wallet</h4>
                <p>{"0"}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Stacked Coin</h4>
                <p>{"0"}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Repurchased Stacked Coin</h4>
                <p>{"0"}</p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {/* <section>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Join Date</th>
              </tr>
            </thead>
          </table>
        </div>
</section> */}
      {/* <div>
        <h4>New Customer List</h4>
        <button>All</button>
      </div> */}
      <section>
        <div>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Join Date</th>
              </tr>
            </thead>
          </table>
        </div>
      </section>
    </Container>
  );
};

export default Dashboard;
