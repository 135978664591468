// src/reducers/adminReducer.js
import * as actionTypes from '../Actions/actionTypes';
import { toast } from 'react-toastify';
const initialState = {
  user: null,
  userData:[],
  paymentOptions: [],
  paymentRequests: [],
  dashboardData: {},
  transactions: [],
  orders:[],
  loading: false,
  error: {code:0,message:null},
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
    case actionTypes.FETCH_PAYMENT_OPTIONS_REQUEST:
    case actionTypes.FETCH_ALL_USERS_REQUESTS_REQUEST:
    case actionTypes.FETCH_ALL_PAYMENT_REQUESTS_REQUEST:
    case actionTypes.APPROVE_PAYMENT_REQUEST_REQUEST:
    case actionTypes.REJECT_PAYMENT_REQUEST_REQUEST:
    case actionTypes.FETCH_DASHBOARD_DATA_REQUEST:
    case actionTypes.FETCH_PAYMENT_TRANSACTIONS_REQUEST:
    case actionTypes.FETCH_ALL_ORDER_REQUEST:
      return { ...state, loading: true, error: null };

    case actionTypes.LOGIN_FAILURE:
    case actionTypes.FETCH_PAYMENT_OPTIONS_FAILURE:
    case actionTypes.FETCH_ALL_PAYMENT_REQUESTS_FAILURE:
    case actionTypes.FETCH_ALL_USERS_REQUESTS_FAILURE:
    case actionTypes.APPROVE_PAYMENT_REQUEST_FAILURE:
    case actionTypes.REJECT_PAYMENT_REQUEST_FAILURE:
    case actionTypes.FETCH_DASHBOARD_DATA_FAILURE:
    case actionTypes.FETCH_PAYMENT_TRANSACTIONS_FAILURE:
    case actionTypes.FETCH_ALL_ORDER_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.LOGIN_SUCCESS:
    // toast.success(action.payload.message);
    localStorage.setItem('authToken', action.payload.token);
      return { ...state, loading: false, user: action.payload, error: null };

    case actionTypes.FETCH_PAYMENT_OPTIONS_SUCCESS:
      return { ...state, loading: false, paymentOptions: action.payload, error: null };

    case actionTypes.FETCH_ALL_PAYMENT_REQUESTS_SUCCESS:
      return { ...state, loading: false, paymentRequests: action.payload, error: null };
      
    case actionTypes.FETCH_ALL_ORDER_SUCCESS:
      return { ...state, loading: false, orders: action.payload, error: null };
    case actionTypes.FETCH_ALL_USERS_REQUESTS_SUCCESS:
      return { ...state, loading: false, userData: action.payload, error: null };

    case actionTypes.APPROVE_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
       
        error: null,
      };

    case actionTypes.REJECT_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        // paymentRequests: state.paymentRequests.filter(request => request.id !== action.payload.id),
        error: null,
      };

    case actionTypes.FETCH_DASHBOARD_DATA_SUCCESS:
      return { ...state, loading: false, dashboardData: action.payload, error: null };

    case actionTypes.FETCH_PAYMENT_TRANSACTIONS_SUCCESS:
      return { ...state, loading: false, transactions: action.payload, error: null };

    default:
      return state;
  }
};

export default adminReducer;
