import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ApprovedFund from './Approved';
import PendingFund from './Pending';
import RejectedFund from './Rejected';
import 'bootstrap/dist/css/bootstrap.min.css';
import './With.css';

const FundControls = () => {
    const bonuses = [
        { title: "Pending Fund", value: "pending_fund" },
        { title: "Approved Fund", value: "approved_fund" },
        { title: "Rejected Fund", value: "rejected_fund" },
    ];
    const [activeTab, setActiveTab] = useState(bonuses[0]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = params.get('tab');
        const activeTab = bonuses.find(bonus => bonus.value === tab) || bonuses[0];
        setActiveTab(activeTab);
        console.log("activeTab", activeTab)
    }, [location.search]);

    function handleTabClick(value) {
        setActiveTab(value);
        navigate(`?tab=${value.value}`);
    }
  return (
    <div className="container mt-4">
    <div className="tab-container">
      {bonuses.map((x, i) => (
        <div
          key={i}
          className={`tab ${x.value === activeTab.value ? "active" : ""}`}
          onClick={() => handleTabClick(x)}
        >
          {x.title}
        </div>
      ))}
    </div>
    <div className="tab-content">
      {activeTab.value === 'pending_fund' && <PendingFund />}
      {activeTab.value === 'approved_fund' && <ApprovedFund />}
      {activeTab.value === 'rejected_fund' && <RejectedFund />}
    {/*
    bonuses.map((x,i)=>{
        return <div style={{background: x?.value === activeTab.value ? "green" :"red" }} onClick={()=>handleTabClick(x)}>
        {x.title}
        </div>
        })
    */}
    </div>
  </div>
    
  )
}

export default FundControls
