// src/api/api.js
import axios from 'axios';
import { toast } from 'react-toastify';

 const API_BASE_URL = 'https://pacewalk.mlmreadymade.com/admin'; // Replace with your API base URL

const apiCall = async (method, endpoint, data = null,showToast=false) => {
 const Authorization = await localStorage.getItem('authToken')
  try {
    const response = await axios({
      method: method,
      url: `${API_BASE_URL}${endpoint}`,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization
      }, 
    });
    console.log(endpoint,response.data);
    showToast && toast.success(response.data.message || "Success")
    return { data: response.data, error: null };
  } catch (error) {
    console.log(endpoint,error)
    let code = 0;
    let errorMessage = 'An unexpected error occurred';
    
    if (error.response) {
      errorMessage = error.response.data.message || error.response.statusText;
      code = error.response.data.code || 0
    } else if (error.request) {
      errorMessage = 'No response from server. Please try again later.';
    } else {
      errorMessage = error.message;
    }

    showToast && toast.error(errorMessage); // Show error message using toast
    return { data: null, error: {code,message:errorMessage} };
  }
};

export default apiCall;
