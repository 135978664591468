import { createStore, combineReducers ,applyMiddleware} from 'redux';
import {thunk} from 'redux-thunk';
import adminReducer from '../Reducers/adminReducer';

const rootReducer = combineReducers({
  admin: adminReducer,
});

const store = createStore(rootReducer,applyMiddleware(thunk));

export default store;
