import React, { useState } from "react";
import "./With.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Change from "../../Common/StringToSub";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTransaction } from "../../Actions/adminActions";
import { Container, Row, Col  } from "react-bootstrap";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
const ApprovedWith = () => {
  const {paymentRequests} = useSelector((state) => state.admin);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);  

  const handlePagination =(page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const filters = {tx_type: 'withdraw',status:'1'};
    const queryParams = {
        page: currentPage,
        limit: 20,
        ...filters
    };
    dispatch(fetchAllTransaction(queryParams))
  }, [dispatch,currentPage]);
  useEffect(()=> {
    setTotalPages(paymentRequests?.totalPages);
  },[paymentRequests])
  return (
    <div className="container mt-4">
    <h2>Data Table</h2>
    <table>
        <thead>
          <tr>
            {/* <th>
              <div className="checkbox-cell">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  className="checkbox-input"
                  id="selectAll"
                />
                <label htmlFor="selectAll" className="checkbox-label">
                  All Select
                </label>
              </div>
            </th> */}
            <th>Name</th>
            <th>Username</th>
            <th>Amount</th>
            <th>Proof</th>
            <th>Transaction id</th>
            <th>Date</th>
            {/* <th>Accounts</th> */}
          </tr>
        </thead>
        <tbody>
          {paymentRequests?.data?.map((row, index) => (
            <tr key={index}>
              {/* <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(index)}
                  onChange={() => toggleSelectRow(index, row.tx_Id)}
                  className="checkbox-input"
                  id={`row-${index}`}
                />
                <label htmlFor={`row-${index}`} className="checkbox-label">
                  <span
                    className={`custom-checkbox ${
                      selectedRows.includes(index) ? "checked" : ""
                    }`}
                  />
                </label>
              </td> */}
              <td>{row.name}</td>
              <td>{row.username}</td>
              <td>{row.amount}</td>
              <td>
              <a href={row.proofUrl} target="_blank" rel="noopener noreferrer">
                View Proof
              </a>
            </td>
            <td>{row.reqest_tx_Id}</td>
              <td>{row.createdAt}</td>
              {/* {row.accountDetails.hasOwnProperty('bank_details') && <td>
                           <p>Bank Name : {row.accountDetails.bank_details.bank_name}</p>
                           <p>IFSC Code : {row.accountDetails.bank_details.ifsc_code}</p>
                           <p>Account Number : {row.accountDetails.bank_details.account_number}</p>
                           <p>Account Holder Name : {row.accountDetails.bank_details.account_holder_name}</p>
                           <p>Account Type : {row.accountDetails.bank_details.account_type}</p>
                           </td>}
                           {row.accountDetails.hasOwnProperty('crypto_address') && <td>Wallet Address : {row.accountDetails.crypto_address}</td>}
                           {row.accountDetails.hasOwnProperty('UPI_ID') && <td>UPI ID : {row.accountDetails.UPI_ID}</td>} */}
            </tr>
          ))}
        </tbody>
      </table>
      <Row>
          <Col md="12">
          {
            <PaginationComponent 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePagination} />
          }       
          </Col>
        </Row>
  </div>
  );
};

export default ApprovedWith;
