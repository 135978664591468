import React, { useState } from "react";
import "./AllUsers.css";
import { Container, Row, Col  } from "react-bootstrap";
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../Actions/adminActions";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
const AllUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {userData} = useSelector((state) => state.admin);
  const [filter, setFilter] = useState("none");
  const [data, setData] = useState([]);  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);   
  const [limitFilter, setLimitFilter] = useState(20); 
  const [search, setsearch] = useState({
    name:'',
    id:'',
    fromDate:'',
    toDate:'',
    status:''
});

  const toggleFilter = () => {
    if (filter === "none") {
      setFilter("flex");
    } else {
      setFilter("none");
    }
  };

  const handlePagination =(page) => {
    // console.log("page",page)
    setCurrentPage(page);
  };

  const handleChange=(e)=>{
    setsearch({[e.target.name]:e.target.value})
  }
  const handleLimitChange = (e) => {
    setLimitFilter(e.target.value);
};

  const AddFilter = () => {
    const filters= search
    setCurrentPage(1);
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      // search:e.target.value,
      ...filters
  };
  dispatch(fetchAllUsers(queryParams))
}

  const resetFilter = () => {
    const queryParams = {
      page: "1",
      limit: 20
  };
  dispatch(fetchAllUsers(queryParams))
  setsearch({   
    name:'',
    id:'',
    fromDate:'',
    toDate:'',
    status:''})
    setLimitFilter(20)
  };

  useEffect (() => {
    console.log("userData",userData);
    const filters = {};
    console.log("passing",currentPage)
    const queryParams = {
        page:currentPage,
        limit: limitFilter,
        ...filters,
    };
    dispatch(fetchAllUsers(queryParams));
  },[dispatch, currentPage, limitFilter]);

  useEffect(() => {
    setTotalPages(userData?.totalPages);
  }, [userData]);


  return (
    <React.Fragment>
      <Container>
        <div className="pagePath">
          <p className="m-0">
            Home / All Users
          </p>
        </div>
        <Row id="filterRow">
          <span id="filterLogo" onClick={() => toggleFilter()}>
            <i>
              <BiFilter />
            </i>
            Filter
          </span>
          <Row className="p-0 m-0" style={{ display: filter }}>
            <Col lg="2" md="4">
              <div>
                <input type="text" placeholder="Search By Name" name="name" value={search.name} onChange={handleChange}/>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input type="text" placeholder="Search User By Id" name="id" value={search.id} onChange={handleChange}/>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input type="date" placeholder="from date"  name="fromDate" value={search.fromDate} onChange={handleChange}/>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input type="date" placeholder="to date" name="toDate" value={search.toDate} onChange={handleChange}/>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select name="limit" value={limitFilter} onChange={handleLimitChange} >
                  <option value="">--All--</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select name="status"  value={search.status} onChange={handleChange}>
                  <option value="">--Status--</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </Col>
            <div>
              <button className="save filter" onClick={AddFilter}>
                Filter{" "}
                <i>
                  <BiFilter />
                </i>
              </button>
              <button className="cancel reset" onClick={resetFilter}>
                Reset{" "}
                <i>
                  <GrPowerReset />
                </i>
              </button>
            </div>
          </Row>
        </Row>
        <Row id="directTableRow">
          <Col md="12" className="p-0">
            <div style={{ width: "100%" }}>
              <table>
                <tr>
                  <th>S No.</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Email Address</th>
                  <th>Mobile Number</th>
                  <th>Joining Date</th>
                  <th>Activation Date</th>
                  <th>Sponsor</th>
                  <th>Edit Profile</th>
                  <th>Action</th>
                </tr>

                {userData?.data?.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>{(i+1)+(limitFilter*(currentPage-1))}</td>
                      <td>
                        <div className={`status-dot ${x?.status === 0 ? "inactive" : "active"}`} />
                        {x?.name}</td>
                      <td>{x?.username}</td>
                      <td>{x?.email}</td>
                      <td>{x?.mobile}</td>
                      <td>{x?.joining_date}</td>
                      <td>{x?.Activation_date}</td>
                      {/*{x?.status === 0 ? (
                        <td style={{ color: "red" }}>Inactive</td>
                      ) : (
                        <td style={{ color: "green" }}>Active</td>
                      )}*/}
                      <td>{x.sponsor_username}</td>
                      <td>
                      <button className="block-btn"
                          onClick={() => navigate('/dashboard/edit-profile', { state: { user: x } })}
                        >
                          Edit Profile
                        </button>
                       </td>
                      <td>
                      {/*<ToggleBlockButton
                          isBlocked={x?.is_blocked === 1}
                          userId={x.uid}
                          
                        />*/}
                        {
                          x?.is_blocked === 0 ?
                          <button className="btn btn-danger block-btn">Block</button> :
                          <button className="btn btn-success block-btn" >Unblock</button>
                        }
                         {/* <button
                          className={`btn block-btn ${
                            x?.is_blocked === 0 ? "btn-danger" : "btn-success"
                          }`}
                          onClick={() => handleToggleBlock(x.id, x?.is_blocked === 1)}
                        >
                          {x?.is_blocked === 0 ? "Block" : "Unblock"}
                        </button> */}
                        {/*<div onClick={()=>(x?.is_blocked === 0)?Block:Unblock}>
                        <input type="checkbox" class="checkbox" id="checkbox" onChange={(e)=>console.log(e.target.checked)}/>
                        <input type="checkbox" class="checkbox" id="checkbox" checked={Block} onChange={(e) => setThemeDarkLight(!themeDarkLight)} />
                        <label for="checkbox" class="checkbox-label">
                        <span class="ball"></span>
                        </label>
                        </div>*/}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
          {
            <PaginationComponent 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePagination} />
          }       
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AllUsers;
