import React from 'react'
import "./Orders.css"
import { Container, Row, Col } from "react-bootstrap";
import OrdersImg from "./../../Images/orders.png"
import { useState } from 'react';
import { useEffect } from 'react';
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import ConvertTime from '../../Common/ConvertTime';
import { useNavigate } from 'react-router-dom';
import { fetchAllOrder } from "../../Actions/adminActions";
import { useDispatch, useSelector } from 'react-redux';
import PaginationComponent from "../../Component/Pagination/PaginationControls";
const Orders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {orders} = useSelector((state) => state.admin);
    const [filter, setFilter] = useState("none");
    const [data, setData] = useState([]);
    const [search, setsearch] = useState({
        amount:'',
        username:'',
        search:'',
        fromDate:'',
        toDate:'',
        status:''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); 
    const [limitFilter, setLimitFilter] = useState(20); 
    const toggleFilter = () => {
      if (filter === "none") {
        setFilter("flex");
      } else {
        setFilter("none");
      }
    };
    const handlePagination =(page) => {
      // console.log("page",page)
      setCurrentPage(page);
    };
    const handleChange=(e)=>{
      setsearch({[e.target.name]:e.target.value})
    }
    const handleLimitChange = (e) => {
      setLimitFilter(e.target.value);
  };
const Addfilter = ()=>{
    const filters = search
    // console.log(search)
    setCurrentPage(1);
    const queryParams = {
        page: currentPage,
        limit: limitFilter,
        // search:e.target.value,
        ...filters
    };
    dispatch(fetchAllOrder(queryParams))

}

const resetFilter = () => {
  const queryParams = {
    page: "1",
    limit: 20
};
dispatch(fetchAllOrder(queryParams))
setsearch({
  amount:'',
  username:'',
  search:'',
  fromDate:'',
  toDate:'',
  status:''
})
setLimitFilter(20)
};

    useEffect(() => {
        const filters = {}
        const queryParams = {
            page: currentPage,
            limit: limitFilter,
            ...filters
        };
        dispatch(fetchAllOrder(queryParams))
        // console.log(orders)
      }, [dispatch,currentPage,limitFilter])
    
      useEffect(() => {
        setTotalPages(orders?.totalPages);
      }, [orders]);
    
      
    return (
        <React.Fragment>
            <Container>
                <div className="pagePath">
                    <p className="m-0">Home / <span>Orders</span></p>
                </div>
                <Row id="filterRow">
          <span id="filterLogo" onClick={() => toggleFilter()}>
            <i>
              <BiFilter />
            </i>
            Filter
          </span>
          <Row className="p-0 m-0" style={{ display: filter }}>
            <Col lg="2" md="4">
              <div>
              <input type="text" name='search' placeholder="Search" value={search.search} onChange={handleChange} />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
              
              <input type="number" name='amount' placeholder="Search By Amount" value={search.amount} onChange={handleChange}/>
               </div>
            </Col>
            <Col lg="2" md="4">
              <div>
              
              <input type="text" name='username' placeholder="Search By UserName" value={search.username} onChange={handleChange} />
               </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input type="date" placeholder="from date" value={search.fromDate} onChange={handleChange}/>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input type="date" placeholder="to date" value={search.toDate} onChange={handleChange}/>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select name="" value={limitFilter} onChange={handleLimitChange}>
                  <option value="">--All--</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </Col>
            <div>
              <button className="save filter" onClick={Addfilter}>
                Filter{" "}
                <i>
                  <BiFilter />
                </i>
              </button>
              <button className="cancel reset" onClick={resetFilter}>
                Reset{" "}
                <i>
                  <GrPowerReset />
                </i>
              </button>
            </div>
          </Row>
        </Row>
                <div id="directIncomeDiv">
                    <div id="ordersImg"><img src={OrdersImg} alt="" /></div>

                    <Row id="directTableRow" className="">
                        <Col md="12" className="p-0">
                            <div style={{ width: "100%" }}>
                                <table>
                                    <tr>
                                        <th>S No.</th>
                                        <th>Uid</th>
                                        <th>name</th>
                                        <th>username</th>
                                        <th>Package Amount ($)</th>
                                        <th>Package Date&Time</th>
                                        <th>Package Status</th>
                                    </tr>
                                    {
                                        orders?.data?.map((x, i) => {
                                            return (
                                                <tr>
                                                    <td>{(i+1)+(limitFilter*(currentPage-1))}</td>
                                                    <td>{x?.uid}</td>
                                                    <td>{x?.name}</td>
                                                    <td>{x?.username}</td>
                                                    <td>{x?.amount}</td>
                                                    <td>{x?.createdAt}</td>
                                                    {
                                                        x?.status === 1 ?
                                                            <td>Approved</td> : <td>Pending</td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
          <Col md="12">
          {
            <PaginationComponent 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePagination} />
          }       
          </Col>
        </Row>
            </Container>
        </React.Fragment>
    )
}

export default Orders