import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Pages/Login/Login';
import Loader from './Component/Loader/Loader';
import LoadingAnimation from './Component/LoadingAnimation/LoadingAnimation';
import { useSelector } from 'react-redux';
import Sidebar from './Sidebar/Sidebar';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  const { loading, error, user } = useSelector((state) => state.admin);


  return (
    <>
   {loading &&  <LoadingAnimation/>}
   <ToastContainer />
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path="/"   element={<Login/>}></Route>
          <Route path="/dashboard/*" end   element={<Sidebar />}></Route>
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    </>
  );
}

export default App;